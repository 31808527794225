<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 신청일 -->
          <c-datepicker
            :range="true"
            name="requestPeriod"
            label="신청기간"
            v-model="searchParam.requestPeriod"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            :plantCd="searchParam.plantCd"
            label="신청부서"
            name="deptCd"
            v-model="searchParam.deptCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 지급일 -->
          <c-datepicker
            :range="true"
            name="givePeriod"
            label="대여기간"
            v-model="searchParam.givePeriod"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="밀폐공간 작업장비 대여 목록"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openNew" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'closedSpaceEquip',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:120px',
            align: 'center',
          },
          {
            name: 'spaceNameName',
            field: 'spaceNameName',
            label: '밀폐공간 명',
            style: 'width:200px',
            type: 'link',
            align: 'center',
          },
          {
            name: 'giveStatusName',
            field: 'giveStatusName',
            label: '진행단계',
            style: 'width:90px',
            align: 'center',
          },
          {
            label: '대여 신청',
            align: 'center',
            child: [
              {
                name: 'equipName',
                field: 'equipName',
                label: '신청품목',
                style: 'width:250px',
                align: 'center',
                sortable: true,
              },
              {
                name: 'requestUserName',
                field: 'requestUserName',
                label: '신청자',
                style: 'width:130px',
                align: 'center',
              },
              {
                name: 'requestDate',
                field: 'requestDate',
                label: '신청일',
                style: 'width:100px',
                align: 'center',
              },
            ]
          },
          {
            label: '대여 및 반납',
            align: 'center',
            child: [
              {
                name: 'givePeriod',
                field: 'givePeriod',
                label: '대여기간',
                style: 'width:120px',
                align: 'center',
              },
              {
                name: 'returnDate',
                field: 'returnDate',
                label: '반납완료일',
                style: 'width:100px',
                align: 'center',
              },
            ]
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        requestStartYmd: '',
        requestEndYmd: '',
        giveStartYmd: '',
        giveEndYmd: '',
        deptCd: '',
        requestPeriod: [],
        givePeriod: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.cse.gives.list.url;
      // code setting
      // list setting 
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    getList() {
      if (this.searchParam.requestPeriod) {
        this.searchParam.requestStartYmd = this.searchParam.requestPeriod[0];
        this.searchParam.requestEndYmd = this.searchParam.requestPeriod[1];
      } else {
        this.searchParam.requestStartYmd = '';
        this.searchParam.requestEndYmd = '';
      }
      if (this.searchParam.givePeriod) {
        this.searchParam.giveStartYmd = this.searchParam.givePeriod[0];
        this.searchParam.giveEndYmd = this.searchParam.givePeriod[1];
      } else {
        this.searchParam.giveStartYmd = '';
        this.searchParam.giveEndYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    openNew() {
      this.linkClick({heaClosedSpaceEquipmentGiveId:'',plantCd:''});
    },
    linkClick(row, col, index) {
      this.popupOptions.title = '밀폐공간 작업장비 대여 상세'; 
      this.popupOptions.param = {
        heaClosedSpaceEquipmentGiveId: row.heaClosedSpaceEquipmentGiveId ? row.heaClosedSpaceEquipmentGiveId : '',
      };
      this.popupOptions.target = () => import(`${'./closedSpaceEquipDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.width = '80%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>